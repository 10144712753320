import "./report.css";
import { useState } from "react";
const ReportGame = ({cdrive}) => {
  const [game, setGame] = useState(cdrive);

  return (
    <div className="container-report">
      <h1>Player: <span>{game.player}</span></h1>
      <div className="report">
        <div className="Container-Bike-report">
          <h2>B Bike</h2>
          <div className="Bike-report">
            <div className="Bike-report-Name">
              <div className="bike-name-text">
                <p>B Bike 1</p>
              </div>
            </div>
            <div className="Bike-report-Today">
              <p>30</p>
            </div>
            <div className="Bike-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
          <div className="Bike-report">
            <div className="Bike-report-Name">
              <div className="bike-name-text">
                <p>B Bike 2</p>
              </div>
            </div>
            <div className="Bike-report-Today">
              <p>30</p>
            </div>
            <div className="Bike-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
        </div>
        {/* Mcar */}
        <div className="Container-Mcar-report">
          <h2>M Mcar</h2>
          <div className="Mcar-report">
            <div className="Mcar-report-Name">
              <div className="bike-name-text">
                <p>M MCar 1</p>
              </div>
            </div>
            <div className="Mcar-report-Today">
              <p>30</p>
            </div>
            <div className="Mcar-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
          <div className="Mcar-report">
            <div className="Mcar-report-Name">
              
              <div className="bike-name-text">
                <p>M MCar 2</p>
              </div>
            </div>
            <div className="Mcar-report-Today">
              <p>30</p>
            </div>
            <div className="Mcar-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
        </div>
        {/* Rcar */}
        <div className="Container-Rcar-report">
          <h2>R Mcar</h2>
          <div className="Rcar-report">
            <div className="Rcar-report-Name">
              <div className="bike-name-text">
                <p>R RCar 1</p>
              </div>
            </div>
            <div className="Rcar-report-Today">
              <p>30</p>
            </div>
            <div className="Rcar-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
          <div className="Rcar-report">
            <div className="Rcar-report-Name">
 
              <div className="bike-name-text">
                <p>R RCar 1</p>
              </div>
            </div>
            <div className="Rcar-report-Today">
              <p>30</p>
            </div>
            <div className="Rcar-report-Summary_Km">
              <p>300</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ReportGame