import "./demo.css";
//import game from "../../data/gamestatus.json";

import audio1 from "../../assets/sound.mp3";
import startBtn from "../../assets/start.png";
import pngStart from "../../assets/pngStart.png";
import stopBtn from "../../assets/stop.png";
import cdriveZone from "../../assets/cdrivezone.png";

import imgReport from "../../assets/report.png";
import Star from "../../assets/star.png";
import On1 from "../../assets/off.png";
import OffRed from "../../assets/on1-khow.png";
import plusImage from "../../assets/plus.png";
import adpstop from "../../assets/adp3.png";
import adprun from "../../assets/autorun.mp4";
import myAutoRun from "../../assets/myautorun.gif";

import gifgame from "../../assets/appgame8.gif";

import shop from "../../assets/c-shop.png";
import kuncha from "../../assets/lottery.png";
import inbest from "../../assets/k-cafebis.png";
import nft from "../../assets/nft.jpg";

import { useEffect, useState, useRef } from "react";
import { /*useParams,*/ useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUri } from "../utils/constant";
//import qs from "qs";

const Demo = () => {
  const [searchParams] = useSearchParams();
  // const user = searchParams.get("uid");
  const user = localStorage.getItem(("user_id"));
  // const user ="YOKK01";
  console.log(user);
  // console.log(user);
  const [run, setRun] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [playerSumCDP, setPlayerSumCDP] = useState(0);
  const [character, setCharacter] = useState({});
  const [canPlay, setCanPlay] = useState(true);
  const [member, setMember] = useState("");
  const [starNumber, setStarNumber] = useState([]);
  const [totalCdp, setTotalCdp] = useState(0);
  const [game, setGame] = useState({
    player: user,
    sumDV: 0,
    sumCDP: 0,
    sumKM: 0,
    playDate: null,
    kmToday: 0,
    Scooter:[],
    Bike: [],
    MCar: [],
    RCar: [],
  });
  const [originGPS, setOriginGPS] = useState({});
  const [countDown, setCountDown] = useState("");
  const [timeout, setTimeOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vip, setVIP] = useState(false);
  const [autoDriveImage, setAutoDriveImage] = useState(false);
  const [inbestState, setInbestState] = useState(false);
  const [shopState, setShopState] = useState(false);
  const [kunchaState, setKunchaState] = useState(false);
  const [nftState, setNftState] = useState(false);

  const [convert, setConvert] = useState(true); //always show convert cdp

  const [report, setReport] = useState([]);
  const audioRef = useRef();
  const effectRan = useRef(false);
  let numStar = [
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
  ];
  let serverDate;
  let time;
  let t;
  let flagT = false;
  let dummyCharacter = {Scooter:[], Bike: [], MCar: [], RCar: [] };
  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
    //timeout: 15000,
  };

  let lat1, long1;
  let lat2, long2;
  let autoDrive = false;

  let adpDistance = 1.0;

  useEffect(() => {
    const countdown = () => {
      const now2 = new Date();
      let hoursleft, minutesleft, secondsleft;

      hoursleft = 23 - now2.getUTCHours();
      minutesleft = 59 - now2.getMinutes();
      secondsleft = 59 - now2.getSeconds();

      if (hoursleft === 0 && minutesleft === 0 && secondsleft === 0) {
        window.alert("Time out.");
        setTimeOut(true);
        clearInterval(t);
        setCanPlay(false);
        setRun(false);
        flagT = true;
      }
      //format 0 prefixes
      if (hoursleft < 10) hoursleft = "0" + hoursleft;
      if (minutesleft < 10) minutesleft = "0" + minutesleft;
      if (secondsleft < 10) secondsleft = "0" + secondsleft;
      if (flagT === false) {
        setCountDown(
          "UTC " + hoursleft + " " + minutesleft + " " + secondsleft
        );
      } else {
        setCountDown("UTC 00 00 00");
      }
    };
    t = setInterval(countdown, 1000);
    getTotalCdp();
  }, []);

  const getTotalCdp =async()=>{
    const user = localStorage.getItem(("user_id"));
    await axios.get(apiUri+'/auto/cdrive/get_cdp_total/'+user)
    .then((res)=>
    setTotalCdp(res.data),
    );
  }

  useEffect(() => {

    function success(pos) {
      const crd = pos.coords;
      setOriginGPS({ lat: crd.latitude, lgn: crd.longitude });
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);

    if (effectRan.current === false) {
      const loadUser = async () => {
        let uid = user === null ? null : user.trim() === "" ? null : user;
        await axios
          .get(apiUri+"/auto/cdrive/sum_all_usecar/" + uid)
          // .get("localhost/public_html/auto/cdrive/sum_all_usecar/" + uid)
          .then((res) => {
            serverOutput(res.data);
            setupGame();
            setLoading(false);
          });
      };
      loadUser();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);


  const myCdriveZone =() =>{
    window.location.replace(apiUri+"/home/cdrivezone");
    // window.location.replace("localhost/public_html/home/cdrivezone");
  }

  const serverOutput = (data) => {
    data.map((charUnit, index) => {
      let dateServer;
      if (index === 0) {
        dateServer = charUnit?.current.split(" ");
        serverDate = new Date(dateServer[0]);

        let flagConvert = Number(charUnit?.convert);
        if (flagConvert === 1) {
          setConvert(true);
        }
      } else if (index === 1) {
        //total sum header
        game.sumKM = Number(charUnit?.sumkm) || 0;
        game.sumDV = Number(charUnit?.sumdv) || 0;
        const words = charUnit?.date_driver?.split(" ");
        if (words === undefined) {
          game.playDate = serverDate;
        } else {
          game.playDate = new Date(words[0]);
        }
      } else if (index === 2) {
        //play car
        let cdpSum = 0;
        dummyCharacter = {Scooter:[], Bike: [], MCar: [], RCar: [] };
        charUnit.map((element) => {
          let category = {
            id: element.prd_id,
            cid: element.cid,
            name: element.package,
            expireDay: Number(element.cdays),
            sumDistance: Math.floor(Number(element.sumDistance)),
            sumCDP: Math.floor(Number(element.sumCDP)),
            factor: Number(element.factor),
            buydate: new Date(element.buydate),
            pointLevel: getLevel(Number(element.sumDistance)),
            age: getAge(Number(element.cdays)),
            level: getPercentageLevel(Number(element.sumDistance)),
            image: "",
          };

          if (category.name === "CONVERT") {
            cdpSum += category.sumCDP;
          } else {
            cdpSum += category.sumDistance * category.factor;
          }

          const words = element.package.split(" ");
          if(words[0] === "S"){
            category.image = "images/scooters/scooter" + words[2] + words[2] + ".png";
            dummyCharacter.Scooter.push(category);
          } else if (words[0] === "B") {
            category.image = "images/bikes/bike" + words[2] + words[2] + ".png";
            dummyCharacter.Bike.push(category);
          } else if (words[0] === "M") {
            category.image = "images/mcars/mcar" + words[2] + words[2] + ".png";
            dummyCharacter.MCar.push(category);
          } else if (words[0] === "R") {
            category.image = "images/rcars/rcar" + words[2] + words[2] + ".png";
            dummyCharacter.RCar.push(category);
          }
        });
        game.sumCDP = cdpSum;
      } else if (index === 3) {
        //bill
        //       let sumdvItem = 0;
        game.Scooter=[];
        game.Bike = [];
        game.MCar = [];
        game.RCar = [];
        charUnit.map((element) => {
          //         sumdvItem += Number(element.dv);
          let category = {
            id: element.product_id,
            cid: element.id,
            name: element.package,
            sumDistance: 0,
            sumCDP: 0,
            buydate: new Date(element.sales_date),
            expireDay: Number(element.cdays),
            pointLevel: getLevel(0),
            age: getAge(Number(element.cdays)),
            level: getPercentageLevel(0 * 0.01),
            image: "",
          };
          let words = element.package.split(" ");
          if(element.cate==="6"){
            category.factor = 0.05;
            category.image = "images/scooters/scooter" + words[2] + words[2] + ".png";
            console.log("test=======>",category.image);
            game.Scooter.push(category);
          }
          else if (element.cate === "1") {
            category.factor = 0.5;
            category.image = "images/bikes/bike" + words[2] + words[2] + ".png";
            game.Bike.push(category);
          } else if (element.cate === "2") {
            category.factor = 5;
            category.image = "images/mcars/mcar" + words[2] + words[2] + ".png";
            game.MCar.push(category);
          } else if (element.cate === "3") {
            category.factor = 50;
            category.image = "images/rcars/rcar" + words[2] + words[2] + ".png";
            game.RCar.push(category);
          }
        });
        //      game.sumDV = sumdvItem;
      } else if (index === 4) {
        //kmtoday
        game.kmToday = Math.floor(Number(charUnit?.kmtoday));
      } else if (index === 5) {
        game.bonusCDPA = Number(charUnit?.cdpbonus_a);
        game.bonusCDPB = Number(charUnit?.cdpbonus_b);
        game.sumCDP = game.sumCDP + game.bonusCDPA + game.bonusCDPB;
      }

      game.Scooter.map((gscooter) => {
        dummyCharacter.Scooter.map((dscooter) => {
          if (dscooter.cid === gscooter.cid) {
            // gscooter.id = dscooter.id;
            // gscooter.cid = dscooter.cid;
            // gscooter.name = dscooter.name;
            gscooter.sumDistance = dscooter.sumDistance;
            gscooter.sumCDP = dscooter.sumCDP;
            // gscooter.buydate = dscooter.buydate;
            gscooter.factor = dscooter.factor;
            // gscooter.image = dscooter.image;
            gscooter.pointLevel = dscooter.pointLevel;
            gscooter.level = dscooter.level;
            //gscooter.expireDay = dscooter.expireDay;
            //gscooter.age = dscooter.age;
          }
        });
      });

      game.Bike.map((gbike) => {
        dummyCharacter.Bike.map((dbike) => {
          if (dbike.cid === gbike.cid) {
            // gbike.id = dbike.id;
            // gbike.cid = dbike.cid;
            // gbike.name = dbike.name;
            gbike.sumDistance = dbike.sumDistance;
            gbike.sumCDP = dbike.sumCDP;
            // gbike.buydate = dbike.buydate;
            gbike.factor = dbike.factor;
            // gbike.image = dbike.image;
            gbike.pointLevel = dbike.pointLevel;
            gbike.level = dbike.level;
            //gbike.expireDay = dbike.expireDay;
            //gbike.age = dbike.age;
          }
        });
      });

      game.MCar.map((gmcar) => {
        dummyCharacter.MCar.map((dmcar) => {
          if (gmcar.cid === dmcar.cid) {
            // gmcar.id = dmcar.id;
            // gmcar.cid = dmcar.cid;
            // gmcar.name = dmcar.name;
            gmcar.sumDistance = dmcar.sumDistance;
            gmcar.sumCDP = dmcar.sumCDP;
            // gmcar.buydate = dmcar.buydate;
            gmcar.factor = dmcar.factor;
            // gmcar.image = dmcar.image;
            gmcar.pointLevel = dmcar.pointLevel;
            gmcar.level = dmcar.level;
            //gmcar.expireDay = dmcar.expireDay;
            //gmcar.age = dmcar.age;
          }
        });
      });

      game.RCar.map((grcar) => {
        dummyCharacter.RCar.map((drcar) => {
          if (grcar.cid === drcar.cid) {
            // grcar.id = drcar.id;
            // grcar.cid = drcar.cid;
            // grcar.name = drcar.name;
            grcar.sumDistance = drcar.sumDistance;
            grcar.sumCDP = drcar.sumCDP;
            // grcar.buydate = drcar.buydate;
            grcar.factor = drcar.factor;
            // grcar.image = drcar.image;
            grcar.pointLevel = drcar.pointLevel;
            grcar.level = drcar.level;
            //grcar.expireDay = drcar.expireDay;
            //grcar.age = drcar.age;
          }
        });
      });
    });
    //--------- OFF IF UPLOAD -----------
    //console.log("Game: ", game);
    //console.log("Dummy: ", dummyCharacter);
    //--------- ------------- -----------

    setPlayerSumCDP(game.sumCDP);
    if (game.kmToday >= 15) {
      window.alert("You got 15 KM, Please do it tomorrow. Thank you");
      setRun(false);
      setCanPlay(false);
      setAutoDriveImage(false);
      setInbestState(true);
      setShopState(true);
      setKunchaState(true);
      setNftState(true);
    }
  };

  const getLevel = (point) => {
    let ret = 0;
    if (point >= 10000) {
      ret = 5;
    } else if (point >= 7000) {
      ret = 4;
    } else if (point >= 5000) {
      ret = 3;
    } else if (point >= 3000) {
      ret = 2;
    } else if (point >= 1000) {
      ret = 1;
    } else {
      ret = 0;
    }
    return ret;
  };

  const getAge = (expireDay) => {
    return Math.round((100 / 1007) * expireDay) + "%";
  };

  const getPercentageLevel = (distance) => {
    return Math.round(distance * 0.01) + "%";
  };

  const setupGame = () => {
    if (game.playDate) {
      const today = serverDate;
      const todayPattern = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const gameDate = new Date(game.playDate);
      const gameDatePattern = new Date(
        gameDate.getFullYear(),
        gameDate.getMonth(),
        gameDate.getDate()
      );
      if (todayPattern.getTime() === gameDatePattern.getTime()) {
        if (game.kmToday >= 15) {
          setCanPlay(false);
          window.alert("You have reached 15KM today.\n Play again tomorrow.");
        }
      }
    }
    game.playDate = serverDate;
    let star = 0;

    if (game.sumDV > 1000000) {
      star = 5;
    } else if (game.sumDV > 800000) {
      star = 4;
    } else if (game.sumDV > 600000) {
      star = 3;
    } else if (game.sumDV > 400000) {
      star = 2;
    } else if (game.sumDV > 200000) {
      star = 1;
    }

    /*
    for (let i = 0; i < star; i++) {
      numStar[i].classname = "";
    }
    */

    setStarNumber(numStar); //set star for render css

    if (game.sumDV >= 1500 && game.sumDV <= 14999) {
      setMember("B");
    } else if (game.sumDV >= 15000 && game.sumDV <= 149990) {
      setMember("M");
    } else if (game.sumDV >= 150000) {
      setMember("R");
    }

    if (game.sumDV >= 100000) {
      setVIP(true);
    }
  };

  const getGPS = async () => {
    console.log("auto: ", autoDrive);
    if (timeout === true) {
      clearInterval(time);
      setAutoDriveImage(false);
      setCanPlay(false);
      setRun(false);
      return;
    }
    if (game.kmToday >= 15) {
      clearInterval(time);
      setAutoDriveImage(false);
      setCanPlay(false);
      setRun(false);
      return;
    }

    if (lat1 === undefined) lat1 = originGPS.lat;
    if (long1 === undefined) long1 = originGPS.lgn;

    if (autoDrive === true) {
      lat1 = lat1 + adpDistance;
      long1 = long1 + adpDistance;
    }

    async function success(pos) {
      const crd = pos.coords;
      lat2 = crd.latitude;
      long2 = crd.longitude;
      let signalIndex = crd.accuracy;
      console.log("New point updated.");

      const url = apiUri+"/auto/cdrive/updateplay_post";
      // const url = "localhost/public_html/auto/cdrive/updateplay_post";
      const parameter = `${url}/${user}/${lat1}/${long1}/${lat2}/${long2}/${signalIndex}`;
      //https://cbank.live/auto/cdrive/updateplay_post/user_id/latitude1/longitude1/latitude2/longitude2/signalIndex

      await axios
        .get(parameter)
        .then((res) => {
          serverOutput(res.data);
          console.log("test updateplay_post", res.data)
        })
        .catch((error) => {
          window.alert("then catch error: " + error);
        });

      lat1 = lat2;
      long1 = long2;
      setOriginGPS({ lat: lat2, lgn: long2 });
    }

    function error(err) {
      console.log(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
    adpDistance += 1.0;
  };

  const showCharacter = (showedCar) => {
    setCharacter(showedCar);
    setShowDialog(!showDialog);
  };

  const reportClick = async () => {
    //window.alert("Your report coming soon.");

    let uid = user === null ? null : user.trim() === "" ? null : user;
    if (uid === null) {
      return;
    }
    const url = apiUri+"/auto/cdrive/dirver_day/" + uid;
    await axios
      .get(url)
      .then((res) => {
        setReport(res.data);
        console.log("test cdrive/dirver_day",res.data)
      })
      .catch((error) => {
        window.alert("then catch error: " + error);
      });

    setShowReport(!showReport);
  };

  const autoDriveHandle = () => {
    if (run) return;

    if (canPlay === false) {
      window.alert("Play again tomorrow.");
      return;
    }

    autoDrive = true;
    setAutoDriveImage(true);

    console.log("auto");
    setRun(true);
    audioRef.current.play();
    time = setInterval(getGPS, 40000);

    // const inbestshow = setTimeout(() => {
    //   setInbestState(true);
    // }, 7000);

    // const shopshow = setTimeout(() => {
    //   setShopState(true);
    // }, 11000);

    const kunchashow = setTimeout(() => {
      setKunchaState(true);
    }, 7000);

    // const nftshow = setTimeout(() => {
    //   setNftState(true);
    // }, 27000);
  };

  const handleClick = () => {
    if (run) return;
    if (canPlay === false) {
      window.alert("Play again tomorrow.");
      return;
    }

    if (vip) {
      autoDrive = true;
      setAutoDriveImage(true);
      console.log("auto");
    } else {
      console.log("normal");
    }

    let uid = user === null ? null : user.trim() === "" ? null : user;
    if (uid === null) {
      window.location.replace(apiUri+"/login");
      return;
    }

    setRun(true);
    audioRef.current.play();
    time = setInterval(getGPS, 40000);

    // const inbestshow = setTimeout(() => {
    //   setInbestState(true);
    // }, 7000);

    // const shopshow = setTimeout(() => {
    //   setShopState(true);
    // }, 11000);

    const kunchashow = setTimeout(() => {
      setKunchaState(true);
    }, 7000);

    // const nftshow = setTimeout(() => {
    //   setNftState(true);
    // }, 27000);
  };

  const handleConvert = () => {
    window.location.replace(apiUri+"/customer/home/convertcdp");
  };

  const handleLink = (urlLink) => {
    window.open(urlLink, "_blank").focus();
  };

  return (
    <>
      {loading && (
        <div
          id="preloader"
          style={{ display: loading ? "block" : "none" }}
        ></div>
      )}
      <div className="demo-app">
        <div
          className="demo-header"
          style={{ display: loading ? "none" : "block" }}
        >
          <img
            src={pngStart}
            alt=""
            className="Gifgame"
            style={{ display: run ? "none" : "block" }}
          />
          <img
            src={gifgame}
            alt=""
            className="Gifgame"
            style={{ display: run ? "block" : "none" }}
          />

          <audio src={audio1} ref={audioRef} type="audio/ogg"></audio>
          <section>
            <div className="cricle" onClick={() => handleClick()}>
              <div className="inner">
                {<img src={run ? startBtn : stopBtn} alt="" />}
              </div>
            </div>
          </section>
          <div className="box-conten1">

          <div className="Bike">
              <div className="Bike-text">
                <p>S Scooter: </p>
              </div>
              <div>
                {game?.Scooter[0] ? (
                  <img
                    src={game.Scooter[0].image}
                    alt=""
                    onClick={() => showCharacter(game.Scooter[0])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>

              <div>
                {game?.Scooter[1] ? (
                  <img
                    src={game.Scooter[1].image}
                    alt=""
                    onClick={() => showCharacter(game.Scooter[1])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Scooter[2] ? (
                  <img
                    src={game.Scooter[2].image}
                    alt=""
                    onClick={() => showCharacter(game.Scooter[2])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Scooter[3] ? (
                  <img
                    src={game.Scooter[3].image}
                    alt=""
                    onClick={() => showCharacter(game.Scooter[3])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Scooter[4] ? (
                  <img
                    src={game.Scooter[4].image}
                    alt=""
                    onClick={() => showCharacter(game.Scooter[4])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
            </div>

            <div className="Bike">
              <div className="Bike-text">
                <p>B Bike: </p>
              </div>
              <div>
                {game?.Bike[0] ? (
                  <img
                    src={game.Bike[0].image}
                    alt=""
                    onClick={() => showCharacter(game.Bike[0])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>

              <div>
                {game?.Bike[1] ? (
                  <img
                    src={game.Bike[1].image}
                    alt=""
                    onClick={() => showCharacter(game.Bike[1])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Bike[2] ? (
                  <img
                    src={game.Bike[2].image}
                    alt=""
                    onClick={() => showCharacter(game.Bike[2])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Bike[3] ? (
                  <img
                    src={game.Bike[3].image}
                    alt=""
                    onClick={() => showCharacter(game.Bike[3])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.Bike[4] ? (
                  <img
                    src={game.Bike[4].image}
                    alt=""
                    onClick={() => showCharacter(game.Bike[4])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
            </div>

            <div className="M-car">
              <div className="M-car-text">
                <p>M Car: </p>
              </div>
              <div>
                {game?.MCar[0] ? (
                  <img
                    src={game.MCar[0].image}
                    alt=""
                    onClick={() => showCharacter(game.MCar[0])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.MCar[1] ? (
                  <img
                    src={game.MCar[1].image}
                    alt=""
                    onClick={() => showCharacter(game.MCar[1])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.MCar[2] ? (
                  <img
                    src={game.MCar[2].image}
                    alt=""
                    onClick={() => showCharacter(game.MCar[2])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.MCar[3] ? (
                  <img
                    src={game.MCar[3].image}
                    alt=""
                    onClick={() => showCharacter(game.MCar[3])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.MCar[4] ? (
                  <img
                    src={game.MCar[4].image}
                    alt=""
                    onClick={() => showCharacter(game.MCar[4])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
            </div>

            <div className="R-car">
              <div className="R-car-text">
                <p>R Car: </p>
              </div>

              <div>
                {game?.RCar[0] ? (
                  <img
                    src={game.RCar[0].image}
                    alt=""
                    onClick={() => showCharacter(game.RCar[0])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.RCar[1] ? (
                  <img
                    src={game.RCar[1].image}
                    alt=""
                    onClick={() => showCharacter(game.RCar[1])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.RCar[2] ? (
                  <img
                    src={game.RCar[2].image}
                    alt=""
                    onClick={() => showCharacter(game.RCar[2])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.RCar[3] ? (
                  <img
                    src={game.RCar[3].image}
                    alt=""
                    onClick={() => showCharacter(game.RCar[3])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
              <div>
                {game?.RCar[4] ? (
                  <img
                    src={game.RCar[4].image}
                    alt=""
                    onClick={() => showCharacter(game.RCar[4])}
                  />
                ) : (
                  <img src={plusImage} alt="" />
                )}
              </div>
            </div>
          </div>
          {showDialog && (
            <div className="Status" onClick={() => setShowDialog(false)}>
              <div className="Status-img-and-Name">
                <div className="Status-img">
                  <div className="pic">
                    <img src={character.image} alt="" />
                  </div>
                </div>
                <div className="Status-Name">
                  <div className="Status-name-text">
                    <p>{character.name}</p>
                  </div>
                </div>
              </div>
              <div className="Status-R-Km-CDP">
                <div className="Status-R">
                  <div className="Status-R-text">
                    <p>LV {character.pointLevel}</p>
                  </div>
                </div>
                <div className="Status-KM">
                  <div className="Status-KM-text">
                    <p>KM</p>
                  </div>
                </div>
                <div className="Status-CDP">
                  <div className="Status-CDP-text">
                    <p>CDP</p>
                  </div>
                </div>
              </div>
              <div className="Status-Today">
                <div className="Status-Today-text-Today">TODAY:</div>
                <div className="Status-Today-text-KM">
                  {game.kmToday.toFixed(0)}
                </div>

                <div className="Status-Today-text-CDP">
                  {(game.kmToday * character.factor).toFixed(1)}
                </div>
              </div>
              <div className="Status-Total">
                <div className="Status-Total-text-TOTAL">TOTAL:</div>
                <div className="Status-Total-text-KM">
                  {character.sumDistance}
                </div>
                <div className="Status-Total-text-CDP">
                  {(character.sumDistance * character.factor).toFixed(1)}
                </div>
              </div>
              <div className="Status-level">
                <div className="Status-lv0">
                  <div className="Status-text-lv">LV1</div>
                  <div className="Status-text-1000km">1,000KM</div>
                </div>
                <div className="Status-lv0">
                  <div className="Status-text-lv">LV2</div>
                  <div className="Status-text-1000km">3,000KM</div>
                </div>
                <div className="Status-lv0">
                  <div className="Status-text-lv">LV3</div>
                  <div className="Status-text-1000km">5,000KM</div>
                </div>
                <div className="Status-lv0">
                  <div className="Status-text-lv">LV4</div>
                  <div className="Status-text-1000km">7,000KM</div>
                </div>
                <div className="Status-lv0">
                  <div className="Status-text-lv">LV5</div>
                  <div className="Status-text-1000km">10,000KM</div>
                </div>
              </div>
              <div id="progress-bar-level">
                <div
                  id="progress-level"
                  style={{ width: character.level }}
                ></div>
              </div>
              <div id="progress-bar">
                <div id="progress-percentage">
                  {character.expireDay} <span>DAYS</span>
                </div>
                <div id="progress" style={{ width: character.age }}></div>
              </div>
              <div className="X">X</div>
            </div>
          )}

          <div className="img-report">
            <img src={imgReport} alt="" onClick={() => reportClick()} />
          </div>

          {/* <div className="img-report">
            <img src={cdriveZone} alt="" onClick={() => myCdriveZone()} />
          </div> */}

          <div className="btn-R">
            <div className="btn-text">B</div>
            <div className="btn-icon">
              {member === "B" ? (
                <img src={On1} alt="" />
              ) : (
                <img src={OffRed} alt="" />
              )}
            </div>
          </div>
          <div className="btn-M">
            <div className="btn-text">M</div>
            <div className="btn-icon">
              {member === "M" ? (
                <img src={On1} alt="" />
              ) : (
                <img src={OffRed} alt="" />
              )}
            </div>
          </div>
          <div className="btn-B">
            <div className="btn-text">R</div>
            <div className="btn-icon">
              {member === "R" ? (
                <img src={On1} alt="" />
              ) : (
                <img src={OffRed} alt="" />
              )}
            </div>
          </div>

          <div className="img-Star">
            <div className="add-star">
              <p>Rank</p>
              {starNumber.map((star, idx) => (
                <div key={idx} className="add-star-img">
                  <img src={Star} alt="" className={star.classname} />
                </div>
              ))}
            </div>
          </div>

          <div className="box-conten2">
            <div className="totalPoit">
              <p>TOTAL</p>
              <span>{game.sumDV}</span>
              <p>DV</p>
              <span className="CDP-namber">
                {totalCdp.toFixed(2)}
                {/* {game.sumKM < 1 ? 0 : playerSumCDP.toFixed(1)} */}
              </span>
              <p>CDP</p>
            </div>
          </div>
          <div className="test-test">{game.kmToday.toFixed(0)} KM</div>

          <div className="Player">
            <p>{game.player}</p>
          </div>

          <div className="time">
            <p>{countDown}</p>
          </div>

          <div className="autoRun3" style={{ display: vip ? "block" : "none" }}>
            {run ? (
              // <video src={adprun} autoPlay loop />
              <img src={myAutoRun} alt="" />
            ) : (
              <img src={adpstop} alt="" onClick={() => autoDriveHandle()} />
            )}
          </div>

          {showReport && (
            <div className="D-report" onClick={() => setShowReport(false)}>
              <div className="report-title">
                <h3>DAILY REPORT</h3>
              </div>
              {report && (
                <div className="H-report">
                  <div className="Drp-date">
                    <div className="H-tital-date">Date</div>
                    <div className="B-date">
                      <div className="">{report[0]?.date_car}</div>
                      <div className="">{report[1]?.date_car}</div>
                      <div className="">{report[2]?.date_car}</div>
                      <div className="">{report[3]?.date_car}</div>
                      <div className="">{report[4]?.date_car}</div>
                      <div className="">{report[5]?.date_car}</div>
                      <div className="">{report[6]?.date_car}</div>
                      <div className="">{report[7]?.date_car}</div>
                      <div className="">{report[8]?.date_car}</div>
                      <div className="">{report[9]?.date_car}</div>
                      <div className="">{report[10]?.date_car}</div>
                      <div className="">{report[11]?.date_car}</div>
                      <div className="">{report[12]?.date_car}</div>
                      <div className="">{report[13]?.date_car}</div>
                      <div className="">{report[14]?.date_car}</div>
                    </div>
                  </div>

                  <div className="Drp-date">
                    <div className="H-tital-km">KM</div>
                    <div className="B-km">
                      <div className="">{Number(report[0]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[1]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[2]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[3]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[4]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[5]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[6]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[7]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[8]?.km).toFixed(0)}</div>
                      <div className="">{Number(report[9]?.km).toFixed(0)}</div>
                      <div className="">
                        {Number(report[10]?.km).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[11]?.km).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[12]?.km).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[13]?.km).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[14]?.km).toFixed(0)}
                      </div>
                    </div>
                  </div>
                  <div className="Drp-date">
                    <div className="H-tital-cdp">CDP</div>
                    <div className="B-cdp">
                      <div className="">
                        {Number(report[0]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[1]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[2]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[3]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[4]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[5]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[6]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[7]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[8]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[9]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[10]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[11]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[12]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[13]?.cdp).toFixed(0)}
                      </div>
                      <div className="">
                        {Number(report[14]?.cdp).toFixed(0)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* last update */}
          {/* <div
            className="swapcdp"
            style={{ display: convert ? "block" : "none" }}
            onClick={() => handleConvert()}
          >
            <button className="btn-style">CDP SWAP</button>
          </div> */}

          <div
            className="menuHiddenMember"
            style={{ display: convert ? "block" : "none" }}
          >
            {/* <div
              className="inbest"
              style={{ display: inbestState ? "block" : "none" }}
              onClick={() => handleLink("https://fenixshop.co/")}
            >
              <img src={inbest} alt="" />
            </div>

            <div
              className="shop"
              style={{ display: shopState ? "block" : "none" }}
              onClick={() => handleLink("https://taobao.ttpcargo.com/")}
            >
              <img src={shop} alt="" />
            </div> */}

            <div
              className="kuncha"
              style={{ display: kunchaState ? "block" : "none" }}
              onClick={() => handleLink("https://fenixlotto.com/")}
            >
              <img src={kuncha} alt="" />
            </div>

            {/* <div 
              className="nft"
              style={{ display: nftState ? "block" : "none" }}
             >
              <img src={nft} alt="" />
            </div> */}
          </div>

          {/* end update */}
        </div>
      </div>
    </>
  );
};

export default Demo;
