import "./demo/login.css";
import Logo from "../assets/favicon.ico";
import localpass from "./Localpass";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import usericon from "../assets/usericon.png";
import leftback from "../assets/leftback.png";
import map from "../assets/map.jpeg";
import slide2 from "../assets/slide2.jpeg";
import scooter from "../assets/scooter.jpeg";
import cdriveLogo from "../assets/cdriveLogo.png";
import axios from "axios";
import "react-slideshow-image/dist/styles.css";
import { Zoom, Slide } from "react-slideshow-image";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { apiUri } from "./utils/constant";
function Login() {
  const [searchParams] = useSearchParams();
  const userid = searchParams.get("uid");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(0);
  const [user, setUser] = useState("");
  const [myCheck, setMyCheck] = useState(0);
  const [userData, setUserData] = useState({});
  const [goback, setGoback] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userid != null) {
      domyLoginUri();
    } else {
      setLoading(true);
      setUser(localStorage?.getItem("userData"));
      setMyCheck(1);
      checkSt();
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (userid != null) {
      domyLoginUri();
    } else {
      setUser(localStorage.getItem("userData"));
      checkSt();
    }
  }, [myCheck]);

  const checkSt = () => {
    if (user != "") {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    } else {
    }
  };
  const _hadleSubmit = async () => {
    if (email == "" || password == "") {
      setCheck(1);
    } else {
      await doLogin();
    }
  };
  const _loginCheck = async () => {
    if (userData["email"] != null) {
      await domyLogin();
    }
  };

  const domyLogin = async () => {
    console.log(password);
    let uri = apiUri + "/auto/cdrive/user_log/";
    await axios
      .get(uri + userData["email"] + "/" + password)
      .then((res) => {
        if (res.status == 200 && (res.data != null || res.data != "")) {
          localStorage.setItem("user_id", res.data["data"]["user_id"]);
          localStorage.setItem("userData", JSON.stringify(res.data["data"]));
          navigate("/main");
        }
      })
      .catch((e) => {
        setCheck(2);
      });
  };

  const domyLoginUri = async () => {
    console.log(password);
    let uri = apiUri + "/auto/cdrive/user_login_url/";
    await axios
      .get(uri + userid)
      .then((res) => {
        if (res.status == 200 && (res.data != null || res.data != "")) {
          localStorage.setItem("user_id", res.data["data"]["user_id"]);
          localStorage.setItem("userData", JSON.stringify(res.data["data"]));
          navigate("/main");
        }
      })
      .catch((e) => {
        setCheck(2);
      });
  };

  const doLogin = async () => {
    let uri = apiUri + "/auto/cdrive/user_log/";
    await axios
      .get(uri + email + "/" + password)
      .then((res) => {
        if (res.status == 200 && (res.data != null || res.data != "")) {
          localStorage.setItem("user_id", res.data["data"]["user_id"]);
          localStorage.setItem("userData", JSON.stringify(res.data["data"]));
          navigate("/main");
        }
      })
      .catch((e) => {
        setCheck(2);
      });
  };

  const _handleEmailChang = (e) => {
    setEmail(e.target.value);
  };

  const _handlePasswChang = (e) => {
    setPassword(e.target.value);
    console.log("email===", password);
  };

  const _clearUser = () => {
    setUserData("");
    setUser(null);
    setGoback("1");
  };

  const _goback = async () => {
    await setGoback("0");
    await setMyCheck(1);
    await setUser(localStorage.getItem("userData"));
    await checkSt();
  };

  const images = [map, slide2, scooter];

  const _regitLink = async () => {
    window.location.href = apiUri + "/register";
  };

  const _clearUserData = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="demo-app">
      {loading ? (
        <div className="loading">
          <img src={cdriveLogo} width="250" />
        </div>
      ) : (
        <div className="back-color">
          <p
            style={{
              color: "white",
              position: "absolute",
              right: "20px",
              top: "35px",
            }}
            onClick={() => _regitLink()}
          >
            sign up
          </p>
          <br />
          {goback == "1" ? (
            ""
          ) : (
            <div>
              <br />
              <br />
            </div>
          )}
          {goback == "1" ? (
            <div style={{ display: "flex" }}>
              <img
                src={leftback}
                style={{ width: "40px", marginTop: "15px", marginLeft: "15px" }}
                onClick={() => _goback()}
              />
              <p
                style={{
                  color: "white",
                  position: "absolute",
                  right: "20px",
                  top: "35px",
                }}
                onClick={() => _regitLink()}
              >
                sign up
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="body-center">
            {userData == null || userData == "" ? (
              ""
            ) : (
              <div> <br /><br />
                {userData["image"] == null || userData == "" ? (
                  <img src={usericon} className="userprofileimg" />
                ) : (
                  <img
                    src={apiUri + userData["image"]}
                    className="userprofileimg"
                  />
                )}
                <h4 style={{ color: "white" }}>{userData["user_id"]}</h4>
              </div>
            )}
            {user != null ? (
              <div>
                <div
                  className="null"
                  style={{
                    backgroundColor: "#f1f1f1",
                    padding: "20px",
                    borderRadius: "5px",
                  }}
                > 
                  <input
                    type="password"
                    className="text-input"
                    name="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => _handlePasswChang(e)}
                    style={{ color: "black" }}
                  />
                  {check == 2 ? (
                    <p style={{ color: "red" }}>
                      <br />
                      Please check your password again!
                    </p>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      fontSize: "12",
                      color: "blue",
                      marginTop: "5px",
                      marginBottom: "10px",
                    }}
                    onClick={() => _regitLink()}
                  >
                    <u>Next sign up</u>
                  </div>
                  {/* <Link to="/localpass"> */}
                  <button className="btnlog" onClick={() => _loginCheck()}>
                    submit
                  </button>
                  {/* </Link> */}
                  <br />
                  <br />
                  <div style={{ fontSize: "14", color: "black" }}>
                    <u onClick={() => _clearUser()}>Change user account</u>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
                <div
                  style={{
                    backgroundColor: "#f1f1f1",
                    padding: "20px",
                    borderRadius: "5px",
                  }}
                >
                  <h3> C-drive Login</h3>
                  <br />
                  <input
                    type="text"
                    className="text-input"
                    name="email"
                    placeholder="example@gmail.com / user id"
                    value={email}
                    onChange={(e) => _handleEmailChang(e)}
                    style={{ color: "black" }}
                  />
                  <br />
                  <br />
                  <input
                    type="password"
                    className="text-input"
                    name="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => _handlePasswChang(e)}
                    style={{ color: "black" }}
                  />
                  <br />
                  {check == 1 ? (
                    <p style={{ color: "red" }}>
                      <br />
                      Please enter your email & password
                    </p>
                  ) : check == 2 ? (
                    <p style={{ color: "red" }}>
                      <br />
                      Please check your email & password again
                    </p>
                  ) : (
                    ""
                  )}
                  <br />
                  <button className="btnlog" onClick={() => _hadleSubmit()}>
                    submit
                  </button>
                </div>
              </div>
            )}
            <br />
            <br />
            {/* <h1 style={{ color: "white" }}>Go to the future</h1>
            <p style={{ color: "white" }}>
            Drive and get points.  Get started quickly.
            </p> */}
            <br />

            {/* <div className="slide-container">
              <Slide>
                {images.map((each, index) => (
                  <img
                    key={index}
                    style={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "10px",
                    }}
                    src={each}
                  />
                ))}
              </Slide>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
export default Login;
