import "./circle.css";

import { useState } from "react";
import startBtn from "../../assets/btn_strat.png";

const Circle = () => {
  const [km, setKM] = useState(2)
  const [period, setPeriod] = useState(32 * km);
  return (
    <div className='start-skill'>
      <div className="start-outer">
        <div className="start-inner">
          <div id="sumKmToday">
            {/* {km} */}
            <img src={startBtn} alt="" className="btnstart"/>
          </div>
        </div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stopColor="#e91e63" />
            <stop offset="100%" stopColor="#673ab7" />
          </linearGradient>
        </defs>
        <circle cx="80" cy="80" r="70" strokeLinecap="round" style={{strokeDashoffset: period}}/>
      </svg>


    </div>
  )
}

export default Circle