import React, { Component, useState } from "react";
import "./utils/main.css";
import topMenu from "../assets/top1.jpg";
import cdrivelg from "../assets/favicon.ico";
import ctbc from "../assets/ctbc.png";
import delivery from "../assets/delivery.png";
import shoping from "../assets/shoping.png";
import scan from "../assets/scan.png";
import travel from "../assets/travel.png";
import investment from "../assets/investment.png";
import bill from "../assets/bill.png";
import topup from "../assets/topup.png";
import withdraw from "../assets/withdraw.png";
import home from "../assets/home.png";
import carcdrive from "../assets/carcdrive.png";
import cbanktext from "../assets/cbanktext.png";
import other from "../assets/other.png";
import usericon from "../assets/usericon.png";
import bonusicon from "../assets/bonusicon.png";
import news from "../assets/news.png";
import charactericon from "../assets/character.png";
import swapicon from "../assets/swapicon.jpg";
import charticon from "../assets/charticon.png";
import nftmenu from "../assets/nftmenu.png";
import bqik from "../assets/bqik.jpeg";
import board from "../assets/board.jpeg";
import jdb from "../assets/jdb.jpeg";
import map from "../assets/map.jpeg";
import slide2 from "../assets/slide2.jpeg";
import scooter from "../assets/scooter.jpeg";
import nbest from "../assets/nbest.jpeg";
import nbest01 from "../assets/inbest01.jpeg";
import nbest02 from "../assets/inbest02.jpeg";
import nbest03 from "../assets/inbest03.jpeg";
import nbest04 from "../assets/inbest04.jpeg";
import ctbcLogo from "../assets/ctbcLogo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { useEffect, useRef } from "react";
import { apiUri } from "./utils/constant";
import axios from "axios";
import { Zoom, Slide } from "react-slideshow-image";
function Main() {
  const navigate = useNavigate();
  const [check, setCheck] = useState(0);
  const [users, setUser] = useState("");
  const [myCheck, setMyCheck] = useState(0);
  const [userData, setUserData] = useState({});
  const [totalCdp, setTotalCdp] = useState(0);
  let me = 0;
  useEffect(() => {
    setUser(localStorage?.getItem("userData"));
    setMyCheck(1);
    checkSt();
    getTotalCdp();
  }, []);
  const getTotalCdp = async () => {
    const user = localStorage.getItem("user_id");
    await axios
      .get(apiUri + "/auto/cdrive/get_cdp_total/" + user)
      .then((res) => setTotalCdp(res.data));
  };

  // =================================================>
  const user = localStorage.getItem("user_id");
  // const user ="YOKK01";
  console.log(user);
  // console.log(user);
  const [run, setRun] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [playerSumCDP, setPlayerSumCDP] = useState(0);
  const [character, setCharacter] = useState({});
  const [canPlay, setCanPlay] = useState(true);
  const [member, setMember] = useState("");
  const [starNumber, setStarNumber] = useState([]);
  const [game, setGame] = useState({
    player: user,
    sumDV: 0,
    sumCDP: 0,
    sumKM: 0,
    playDate: null,
    kmToday: 0,
    Bike: [],
    MCar: [],
    RCar: [],
  });
  const [originGPS, setOriginGPS] = useState({});
  const [countDown, setCountDown] = useState("");
  const [timeout, setTimeOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vip, setVIP] = useState(false);
  const [autoDriveImage, setAutoDriveImage] = useState(false);
  const [inbestState, setInbestState] = useState(false);
  const [shopState, setShopState] = useState(false);
  const [kunchaState, setKunchaState] = useState(false);
  const [nftState, setNftState] = useState(false);

  const [convert, setConvert] = useState(true); //always show convert cdp

  const [report, setReport] = useState([]);
  const audioRef = useRef();
  const effectRan = useRef(false);
  let numStar = [
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
    { classname: "star3" },
  ];
  let serverDate;
  let time;
  let t;
  let flagT = false;
  let dummyCharacter = { Bike: [], MCar: [], RCar: [] };
  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
    //timeout: 15000,
  };

  let lat1, long1;
  let lat2, long2;
  let autoDrive = false;

  let adpDistance = 1.0;

  useEffect(() => {
    const countdown = () => {
      const now2 = new Date();
      let hoursleft, minutesleft, secondsleft;

      hoursleft = 23 - now2.getUTCHours();
      minutesleft = 59 - now2.getMinutes();
      secondsleft = 59 - now2.getSeconds();

      if (hoursleft === 0 && minutesleft === 0 && secondsleft === 0) {
        window.alert("Time out.");
        setTimeOut(true);
        clearInterval(t);
        setCanPlay(false);
        setRun(false);
        flagT = true;
      }
      //format 0 prefixes
      if (hoursleft < 10) hoursleft = "0" + hoursleft;
      if (minutesleft < 10) minutesleft = "0" + minutesleft;
      if (secondsleft < 10) secondsleft = "0" + secondsleft;
      if (flagT === false) {
        setCountDown(
          "UTC " + hoursleft + " " + minutesleft + " " + secondsleft
        );
      } else {
        setCountDown("UTC 00 00 00");
      }
    };
    t = setInterval(countdown, 1000);
  }, []);

  useEffect(() => {
    function success(pos) {
      const crd = pos.coords;
      setOriginGPS({ lat: crd.latitude, lgn: crd.longitude });
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);

    if (effectRan.current === false) {
      const loadUser = async () => {
        let uid = user === null ? null : user.trim() === "" ? null : user;
        await axios
          .get(apiUri + "/auto/cdrive/sum_all_usecar/" + uid)
          // .get("localhost/public_html/auto/cdrive/sum_all_usecar/" + uid)
          .then((res) => {
            serverOutput(res.data);
            setupGame();
            setLoading(false);
          });
      };
      loadUser();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const myCdriveZone = () => {
    window.location.replace("https://cbank.live/home/cdrivezone");
    // window.location.replace("localhost/public_html/home/cdrivezone");
  };

  const serverOutput = (data) => {
    data.map((charUnit, index) => {
      let dateServer;
      if (index === 0) {
        dateServer = charUnit?.current.split(" ");
        serverDate = new Date(dateServer[0]);

        let flagConvert = Number(charUnit?.convert);
        if (flagConvert === 1) {
          setConvert(true);
        }
      } else if (index === 1) {
        //total sum header
        game.sumKM = Number(charUnit?.sumkm) || 0;
        game.sumDV = Number(charUnit?.sumdv) || 0;
        const words = charUnit?.date_driver?.split(" ");
        if (words === undefined) {
          game.playDate = serverDate;
        } else {
          game.playDate = new Date(words[0]);
        }
      } else if (index === 2) {
        //play car
        let cdpSum = 0;
        dummyCharacter = { Bike: [], MCar: [], RCar: [] };
        charUnit.map((element) => {
          let category = {
            id: element.prd_id,
            cid: element.cid,
            name: element.package,
            expireDay: Number(element.cdays),
            sumDistance: Math.floor(Number(element.sumDistance)),
            sumCDP: Math.floor(Number(element.sumCDP)),
            factor: Number(element.factor),
            buydate: new Date(element.buydate),
            pointLevel: getLevel(Number(element.sumDistance)),
            age: getAge(Number(element.cdays)),
            level: getPercentageLevel(Number(element.sumDistance)),
            image: "",
          };

          if (category.name === "CONVERT") {
            cdpSum += category.sumCDP;
          } else {
            cdpSum += category.sumDistance * category.factor;
          }

          const words = element.package.split(" ");
          if (words[0] === "B") {
            category.image = "images/bikes/bike" + words[2] + words[2] + ".png";
            dummyCharacter.Bike.push(category);
          } else if (words[0] === "M") {
            category.image = "images/mcars/mcar" + words[2] + words[2] + ".png";
            dummyCharacter.MCar.push(category);
          } else if (words[0] === "R") {
            category.image = "images/rcars/rcar" + words[2] + words[2] + ".png";
            dummyCharacter.RCar.push(category);
          }
        });
        game.sumCDP = cdpSum;
      } else if (index === 3) {
        //bill
        //       let sumdvItem = 0;
        game.Bike = [];
        game.MCar = [];
        game.RCar = [];
        charUnit.map((element) => {
          //         sumdvItem += Number(element.dv);
          let category = {
            id: element.product_id,
            cid: element.id,
            name: element.package,
            sumDistance: 0,
            sumCDP: 0,
            buydate: new Date(element.sales_date),
            expireDay: Number(element.cdays),
            pointLevel: getLevel(0),
            age: getAge(Number(element.cdays)),
            level: getPercentageLevel(0 * 0.01),
            image: "",
          };
          let words = element.package.split(" ");
          if (element.cate === "1") {
            category.factor = 0.5;
            category.image = "images/bikes/bike" + words[2] + words[2] + ".png";
            game.Bike.push(category);
          } else if (element.cate === "2") {
            category.factor = 5;
            category.image = "images/mcars/mcar" + words[2] + words[2] + ".png";
            game.MCar.push(category);
          } else if (element.cate === "3") {
            category.factor = 50;
            category.image = "images/rcars/rcar" + words[2] + words[2] + ".png";
            game.RCar.push(category);
          }
        });
        //      game.sumDV = sumdvItem;
      } else if (index === 4) {
        //kmtoday
        game.kmToday = Math.floor(Number(charUnit?.kmtoday));
      } else if (index === 5) {
        game.bonusCDPA = Number(charUnit?.cdpbonus_a);
        game.bonusCDPB = Number(charUnit?.cdpbonus_b);
        game.sumCDP = game.sumCDP + game.bonusCDPA + game.bonusCDPB;
        // game.sumCDP = game.sumCDP;
      }

      game.Bike.map((gbike) => {
        dummyCharacter.Bike.map((dbike) => {
          if (dbike.cid === gbike.cid) {
            // gbike.id = dbike.id;
            // gbike.cid = dbike.cid;
            // gbike.name = dbike.name;
            gbike.sumDistance = dbike.sumDistance;
            gbike.sumCDP = dbike.sumCDP;
            // gbike.buydate = dbike.buydate;
            gbike.factor = dbike.factor;
            // gbike.image = dbike.image;
            gbike.pointLevel = dbike.pointLevel;
            gbike.level = dbike.level;
            //gbike.expireDay = dbike.expireDay;
            //gbike.age = dbike.age;
          }
        });
      });

      game.MCar.map((gmcar) => {
        dummyCharacter.MCar.map((dmcar) => {
          if (gmcar.cid === dmcar.cid) {
            // gmcar.id = dmcar.id;
            // gmcar.cid = dmcar.cid;
            // gmcar.name = dmcar.name;
            gmcar.sumDistance = dmcar.sumDistance;
            gmcar.sumCDP = dmcar.sumCDP;
            // gmcar.buydate = dmcar.buydate;
            gmcar.factor = dmcar.factor;
            // gmcar.image = dmcar.image;
            gmcar.pointLevel = dmcar.pointLevel;
            gmcar.level = dmcar.level;
            //gmcar.expireDay = dmcar.expireDay;
            //gmcar.age = dmcar.age;
          }
        });
      });

      game.RCar.map((grcar) => {
        dummyCharacter.RCar.map((drcar) => {
          if (grcar.cid === drcar.cid) {
            // grcar.id = drcar.id;
            // grcar.cid = drcar.cid;
            // grcar.name = drcar.name;
            grcar.sumDistance = drcar.sumDistance;
            grcar.sumCDP = drcar.sumCDP;
            // grcar.buydate = drcar.buydate;
            grcar.factor = drcar.factor;
            // grcar.image = drcar.image;
            grcar.pointLevel = drcar.pointLevel;
            grcar.level = drcar.level;
            //grcar.expireDay = drcar.expireDay;
            //grcar.age = drcar.age;
          }
        });
      });
    });
    //--------- OFF IF UPLOAD -----------
    //console.log("Game: ", game);
    //console.log("Dummy: ", dummyCharacter);
    //--------- ------------- -----------

    setPlayerSumCDP(game.sumCDP);
    if (game.kmToday >= 15) {
      // window.alert("You got 15 KM.");
      setRun(false);
      setCanPlay(false);
      setAutoDriveImage(false);
      setInbestState(true);
      setShopState(true);
      setKunchaState(true);
      setNftState(true);
    }
  };

  const getLevel = (point) => {
    let ret = 0;
    if (point >= 10000) {
      ret = 5;
    } else if (point >= 7000) {
      ret = 4;
    } else if (point >= 5000) {
      ret = 3;
    } else if (point >= 3000) {
      ret = 2;
    } else if (point >= 1000) {
      ret = 1;
    } else {
      ret = 0;
    }
    return ret;
  };

  const getAge = (expireDay) => {
    return Math.round((100 / 1007) * expireDay) + "%";
  };

  const getPercentageLevel = (distance) => {
    return Math.round(distance * 0.01) + "%";
  };

  const setupGame = () => {
    if (game.playDate) {
      const today = serverDate;
      const todayPattern = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const gameDate = new Date(game.playDate);
      const gameDatePattern = new Date(
        gameDate.getFullYear(),
        gameDate.getMonth(),
        gameDate.getDate()
      );
      if (todayPattern.getTime() === gameDatePattern.getTime()) {
        if (game.kmToday >= 15) {
          setCanPlay(false);
          window.alert("You have reached 15KM today.\n Play again tomorrow.");
        }
      }
    }
    game.playDate = serverDate;
    let star = 0;

    if (game.sumDV > 1000000) {
      star = 5;
    } else if (game.sumDV > 800000) {
      star = 4;
    } else if (game.sumDV > 600000) {
      star = 3;
    } else if (game.sumDV > 400000) {
      star = 2;
    } else if (game.sumDV > 200000) {
      star = 1;
    }

    /*
    for (let i = 0; i < star; i++) {
      numStar[i].classname = "";
    }
    */

    setStarNumber(numStar); //set star for render css

    if (game.sumDV >= 1500 && game.sumDV <= 14999) {
      setMember("B");
    } else if (game.sumDV >= 15000 && game.sumDV <= 149990) {
      setMember("M");
    } else if (game.sumDV >= 150000) {
      setMember("R");
    }

    if (game.sumDV >= 100000) {
      setVIP(true);
    }
  };

  const getGPS = async () => {
    console.log("auto: ", autoDrive);
    if (timeout === true) {
      clearInterval(time);
      setAutoDriveImage(false);
      setCanPlay(false);
      setRun(false);
      return;
    }
    if (game.kmToday >= 15) {
      clearInterval(time);
      setAutoDriveImage(false);
      setCanPlay(false);
      setRun(false);
      return;
    }

    if (lat1 === undefined) lat1 = originGPS.lat;
    if (long1 === undefined) long1 = originGPS.lgn;

    if (autoDrive === true) {
      lat1 = lat1 + adpDistance;
      long1 = long1 + adpDistance;
    }

    async function success(pos) {
      const crd = pos.coords;
      lat2 = crd.latitude;
      long2 = crd.longitude;
      let signalIndex = crd.accuracy;
      console.log("New point updated.");

      const url = apiUri + "/auto/cdrive/updateplay_post";
      // const url = "localhost/public_html/auto/cdrive/updateplay_post";
      const parameter = `${url}/${user}/${lat1}/${long1}/${lat2}/${long2}/${signalIndex}`;
      //https://cbank.live/auto/cdrive/updateplay_post/user_id/latitude1/longitude1/latitude2/longitude2/signalIndex

      await axios
        .get(parameter)
        .then((res) => {
          serverOutput(res.data);
          console.log("test updateplay_post", res.data);
        })
        .catch((error) => {
          window.alert("then catch error: " + error);
        });

      lat1 = lat2;
      long1 = long2;
      setOriginGPS({ lat: lat2, lgn: long2 });
    }

    function error(err) {
      console.log(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
    adpDistance += 1.0;
  };

  const showCharacter = (showedCar) => {
    setCharacter(showedCar);
    setShowDialog(!showDialog);
  };

  const reportClick = async () => {
    //window.alert("Your report coming soon.");

    let uid = user === null ? null : user.trim() === "" ? null : user;
    if (uid === null) {
      return;
    }
    const url = apiUri + "/auto/cdrive/dirver_day/" + uid;
    await axios
      .get(url)
      .then((res) => {
        setReport(res.data);
        console.log("test cdrive/dirver_day", res.data);
      })
      .catch((error) => {
        window.alert("then catch error: " + error);
      });

    setShowReport(!showReport);
  };

  const autoDriveHandle = () => {
    if (run) return;

    if (canPlay === false) {
      window.alert("Play again tomorrow.");
      return;
    }

    autoDrive = true;
    setAutoDriveImage(true);

    console.log("auto");
    setRun(true);
    audioRef.current.play();
    time = setInterval(getGPS, 40000);

    // const inbestshow = setTimeout(() => {
    //   setInbestState(true);
    // }, 7000);

    // const shopshow = setTimeout(() => {
    //   setShopState(true);
    // }, 11000);

    const kunchashow = setTimeout(() => {
      setKunchaState(true);
    }, 7000);

    // const nftshow = setTimeout(() => {
    //   setNftState(true);
    // }, 27000);
  };

  const handleClick = () => {
    if (run) return;
    if (canPlay === false) {
      window.alert("Play again tomorrow.");
      return;
    }

    if (vip) {
      autoDrive = true;
      setAutoDriveImage(true);
      console.log("auto");
    } else {
      console.log("normal");
    }

    let uid = user === null ? null : user.trim() === "" ? null : user;
    if (uid === null) {
      window.location.replace(apiUri + "/login");
      return;
    }

    setRun(true);
    audioRef.current.play();
    time = setInterval(getGPS, 40000);

    // const inbestshow = setTimeout(() => {
    //   setInbestState(true);
    // }, 7000);

    // const shopshow = setTimeout(() => {
    //   setShopState(true);
    // }, 11000);

    const kunchashow = setTimeout(() => {
      setKunchaState(true);
    }, 7000);

    // const nftshow = setTimeout(() => {
    //   setNftState(true);
    // }, 27000);
  };

  const handleConvert = () => {
    window.location.replace(apiUri + "/customer/home/convertcdp");
  };

  const handleLink = (urlLink) => {
    window.open(urlLink, "_blank").focus();
  };

  // =================================================>

  useEffect(() => {
    setUser(localStorage.getItem("userData"));
    checkSt();
  }, [myCheck]);

  const checkSt = () => {
    if (users != "") {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    } else {
    }
  };
  const _logout = () => {
    // localStorage.clear();
    navigate("/");
  };

  const _news = () => {
    window.location.href = apiUri + "/news";
  };

  const _characor = () => {
    window.location.href = apiUri + "/cdrives";
  };

  const _bonus = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=bonus";
  };

  const _mychart = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=chart";
  };
  const _swap = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=swap";
  };

  const _minning = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=aleo";
  };

  const _topUp = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=topup";
  };

  const _profile = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=profile";
  };

  const _charactor = () => {
    window.location.href =
      apiUri + "/home/cdrivezone/?uid=" + userData["user_id"] + "&vl=charactor";
  };

  const images = [nbest, nbest01, nbest02, nbest03, nbest04];
  return (
    <div className="demo-app">
      <div className="body">
        {/* top menu */}
        <div className="topnav">
          {/* <img src={topMenu} width="70" /> */}
          <br />
          <br />
          <br />
          <br />
          <div className="idclass">ID {userData["user_id"]}</div>
          <div className="cdriveLogo">
            {/* {userData["image"] == null || userData == "" ? (
              <img src={usericon} className="userprofileimg" />
            ) : (
              <img
                src={"https://cbank.live" + userData["image"]}
                className="userprofileimg"
              />
            )} */}
            <Popup
              trigger={
                userData["image"] == null || userData == "" ? (
                  <img src={usericon} className="userprofileimg" />
                ) : (
                  <img
                    src={apiUri + "/" + userData["image"]}
                    className="userprofileimg"
                  />
                )
              }
              position="bottom right"
            >
              <div className="subdropmenu" onClick={() => _profile()}>
                Profile
              </div>
              <hr />
              <div className="subdropmenu" onClick={() => _logout()}>
                LogOut
              </div>
            </Popup>
          </div>
        </div>
        {/* body show poin */}
        <div className="showpoint">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <table>
              <td
                style={{ fontSize: "16px", paddingRight: "5px" }}
                className="greentext"
              >
                CDP
              </td>
              <td style={{ fontSize: "34px" }} className="greentext">
                {totalCdp.toFixed(2)}
                {/* {game.sumKM < 1 ? 0 : playerSumCDP.toFixed(1)} */}
              </td>
              <td
                style={{ fontSize: "16px", paddingLeft: "1px" }}
                className="greentext"
              ></td>
            </table>
          </div>
          <hr className="hrstyle" />
          <div className="historytran">
            <p style={{ verticalAlign: "right", justifySelf: "right" }}></p>
          </div>
          <div className="idticket">
            <Slide>
              {images.map((each, index) => (
                <div style={{ justifyContent: "center" }}>
                  <img
                    key={index}
                    style={{
                      width: "100%",
                      height: "170px",
                      borderRadius: "10px",
                    }}
                    src={each}
                  />
                </div>
              ))}
            </Slide>
          </div>
        </div>
        <div className="dashboadmenu">
          <table width="100%">
            <tr>
              <td onClick={() => _charactor()} width="25%"><center>
                <div className="d-menu-background">
                <img src={charactericon} className="menu-image" />
                </div></center>
              </td>
              <td width="25%">
                <center>
                  <div className="d-menu-background"><center><img src={shoping} className="menu-image" /></center></div>
                </center>
              </td>
              <td width="25%">
                <center>
                  <div className="d-menu-background"><center><img src={nftmenu} className="menu-image" /></center></div>
                </center>
              </td>
              <td onClick={() => _bonus()} width="25%">
                <center>
                  <div className="d-menu-background"><center><img src={bonusicon} className="menu-image" /></center></div>
                </center>
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td onClick={() => _charactor()}>Charactor</td>
              <td>Shop</td>
              <td>NFT</td>
              <td onClick={() => _bonus()}>Bonus</td>
            </tr>
            <tr>
              <td onClick={() => _topUp()}>
                <center>
                  <div className="d-menu-background"><center><img src={topup} className="menu-image" /></center></div>
                </center>
              </td>
              <td onClick={() => _minning()}>
                <center>
                  <div className="d-menu-background"><center><img src={withdraw} className="menu-image" /></center></div>
                </center>
              </td>
              <td onClick={() => _mychart()}>
                <center>
                  <div className="d-menu-background"><center><img
                    src={charticon}
                    className="menu-image"
                    onClick={() => _mychart()}
                  /></center></div>
                </center>
              </td>
              <td onClick={() => _swap()}>
                <center>
                  <div className="d-menu-background"><center><img src={swapicon} className="menu-image" /></center></div>
                </center>
              </td>
            </tr>
            <tr style={{ textAlign: "center" }}>
              <td onClick={() => _topUp()}>Topup</td>
              <td onClick={() => _minning()}>Mining</td>
              <td onClick={() => _mychart()}>Referral</td>
              <td onClick={() => _swap()}>Swap</td>
            </tr>
          </table>
        </div>
        <div
          className="bottomnav"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <table>
            <td style={{ textAlign: "center" }}>
              <img src={home} width="30px" />
              <p className="navbtext">Home</p>
            </td>
            <td
              style={{ textAlign: "center" }}
              onClick={() => (window.location.href = apiUri)}
            >
              <img src={cbanktext} width="60px" />
              <p className="navbtext">Trade</p>
            </td>
            <td style={{ paddingLeft: "60px", paddingRight: "50px" }}></td>
            <td style={{ textAlign: "center" }}>
              <img src={scan} width="30px" />
              <p className="navbtext">Scan</p>
            </td>
            <td style={{ textAlign: "center" }}>
              <img src={other} width="40px" />
              <p className="navbtext">Other</p>
            </td>
          </table> */}
          <div className="ctbc" onClick={() => navigate("/cdrive")}>
            <img src={carcdrive} width="140px" />
            <p className="navbtext">Start Drive</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;