import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import { Routes, Route } from "react-router-dom";
import Circle from "./pages/circle/Circle";

import Demo from "./pages/demo/Demo";
import Login from "./pages/Login";
import ReportGame from "./pages/report/ReportGame";
import Localpass from "./pages/Localpass";
import Main from "./pages/main";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
const client = new ApolloClient({
  uri: "http://128.199.21.25:4000/graphql",
  //uri: 'http://localhost:4000/graphql',
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Routes basename="/">
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/driver" element={<Demo />} />
        <Route exact path="/:user" element={<Demo />} />
        <Route exact path="/localpass" element={<Localpass />} />
        <Route exact path="/report" element={<ReportGame />} />
        <Route exact path="/circle" element={<Circle />} />
        <Route exact path="/main" element={<Main />} />
        <Route path="/main" element={<Main />} />
      </Routes>
    </ApolloProvider>
  );
};

export default App;
/*

const App = () => {
  return (
    <ApolloProvider client={client}>

      <Routes>
        <Route path='/demo' element={<Demo />} />
        
        <Route path='/' element={<Layout />} >
          <Route index element={<Home />} />
          <Route path='store' element={<Store />} />
          <Route path='digitalworld' element={<DigitalWorld />} />
          <Route path='products' element={<Products />} />
          <Route path='contact' element={<Contact />} />

        <Route path="user">
          <Route index element={<UsersList />} />
          <Route path=":userId" element={<UserPage />} />
        </Route> 
          }
          <Route path='comingsoon' element={<Commingsoon />} />
          <Route path="*" element={<Navigate to="comingsoon" replace />} />
        </Route>

      </Routes>
    </ApolloProvider>
  );

}

export default App;


*/
