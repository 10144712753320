import "./demo/login.css";
import { useState } from "react";
import delIcon from "../assets/clearicon.png"
import { Link, useNavigate} from "react-router-dom";
import locklogo from "../assets/locklogo.png";
import leftback from "../assets/leftback.png";
const Localpass = () => {
  const naviaget = useNavigate();
  const [numInput, setNumInput] = useState("");
  const one =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"1");
    }
  }
  const two =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"2");
    }
  }
  const three =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"3");
    }
  }
  const four =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"4");
    }
  }
  const five =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"5");
    }
  }
  const six =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"6");
    }
  }
  const seven =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"7");
    }
  }
  const eight =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"8");
    };
  }
  const nine =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"9");
    }
  }
  const zero =()=>{
    if(numInput.length<6){
      setNumInput(numInput+"0");
    }
  }
  const remove = ()=>{
    setNumInput(numInput.slice(0,-1));
  }
  const _handleChangeNum =(e)=>{
    setNumInput(e.target.value);
    console.log(numInput);
  }

  const _handleSubmit =()=>{
    if(numInput=="" || numInput==null){
      alert("Enter a 6-digit password")
    }else if(numInput.length<6){
      alert("the Lock Password can not less than 6 characters")
    }else{
      naviaget('/main');
      console.log(numInput); 
    }
  }
  return (
    <div className="demo-app">
      <div className="back-color">
      <img src={leftback} style={{width:"40px", marginTop:"15px", marginLeft:"15px"}} onClick={()=>naviaget(-1)} />
        <div className="body-center">
          <img src={locklogo} width="120" />
          <br /><br />
          <p style={{color:"white"}}>Enter a 6-digit password</p>
          {/* <br /> */}
          <div className="flex-lock">
            {numInput.length>=1?<div className="set"></div>:<div className="unset"></div>}
            {numInput.length>=2?<div className="set"></div>:<div className="unset"></div>}
            {numInput.length>=3?<div className="set"></div>:<div className="unset"></div>}
            {numInput.length>=4?<div className="set"></div>:<div className="unset"></div>}
            {numInput.length>=5?<div className="set"></div>:<div className="unset"></div>}
            {numInput.length>=6?<div className="set"></div>:<div className="unset"></div>}
          </div>
        {/* <input
                type="text"
                className="text-input"
                name="userName"
                maxLength={6}
                value={numInput}
                onChange={(e)=> _handleChangeNum(e)}
                placeholder="Enter your password"
              /> */}
              <br /><br />
        <div>
            <button className="number-btn" onClick={()=>one()}>1</button>
            <button className="number-btn" onClick={()=>two()}>2</button>
            <button className="number-btn" onClick={()=>three()}>3</button>
        </div>
        <div>
            <button className="number-btn" onClick={()=>four()}>4</button>
            <button className="number-btn" onClick={()=>five()}>5</button>
            <button className="number-btn" onClick={()=>six()}>6</button>
        </div>
        <div>
            <button className="number-btn" onClick={()=>seven()}>7</button>
            <button className="number-btn" onClick={()=>eight()}>8</button>
            <button className="number-btn" onClick={()=>nine()}>9</button>
        </div>
        <div>
        <button className="number-btn" onClick={()=>zero()}>0</button>
        <button className="number-btn" onClick={()=>remove()}><img src= {delIcon} width="25" style={{paddingTop:1}}/></button>
        </div>
        <br /><br />
        <div>
          {/* <Link to="/demo"> */}
          <button className="btnlog" onClick={()=> _handleSubmit()}>submit</button>
          {/* </Link> */}
          </div> 
        </div>
        
      </div>
    </div>
  );
};

export default Localpass;
